import React from 'react'
import PropTypes from 'prop-types'
import styles from './btn-menu.module.scss'

const BtnMenu = ({ active, setActive }) => (
  <button
    type='button'
    className={styles.el}
    aria-label='Toggle Menu'
    onClick={() => setActive(!active)}
    data-open={active}
  >
    <div className={styles.el__menu}>
      <div className={styles.el__menu__line} />
      <div className={styles.el__menu__line} />
      <div className={styles.el__menu__line} />
    </div>
    <div className={styles.el__close}>
      <div className={styles.el__close__line} />
      <div className={styles.el__close__line} />
    </div>
  </button>
)

BtnMenu.defaultProps = {
  active: false,
  setActive: () => undefined,
}

BtnMenu.propTypes = {
  active: PropTypes.bool,
  setActive: PropTypes.func,
}

export default BtnMenu
