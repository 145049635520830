import React, { ReactNode } from 'react'
import { Link as GatsbyLink } from 'gatsby'


export interface Props {
  to: string,
  children: ReactNode,
  target?: Basiis.LinkTarget,
  className?: string,
  onClick?: (event: React.SyntheticEvent<HTMLSpanElement, Event>) => void,
  download?: boolean,
}

const Link = ({
  to,
  children,
  target,
  className,
  onClick,
  download = false,
  ...attributes
}: Props) => {
  // This assumes internal links will start with exactly one slash,
  // and that anything else is external.
  const internal = to ? /^\/(?!\/)/.test(to) : false
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        onClick={onClick}
        {...attributes}
      >
        {children}
      </GatsbyLink>
    )
  }

  if (to) {
    // check if extenal link
    let relOutput = ''
    let targetOutput = ''
    if (target === '_blank') {
      targetOutput = '_blank'
      relOutput = 'noopener noreferrer'
    }

    return (
      <a
        href={to}
        className={className}
        target={targetOutput}
        rel={relOutput}
        download={download}
        {...attributes}
      >
        {children}
      </a>
    )
  }

  if (onClick) {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        e.preventDefault()
        onClick(e)
      }
    }

    return (
      <span
        role="button"
        className={className}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        {...attributes}
      >
        {children}
      </span>
    )
  }

  return (
    <span className={className} {...attributes}>
      {children}
    </span>
  )
}


export default Link
