import React, { useState, useRef } from 'react'
import { motion } from 'framer-motion'

import Link from '../link/link'
import BtnMenu from '../btn-menu'
import Container from '../container'
import Menu from '../menu/menu'
import LanguageSelector from './language-selector'
import {
  linkResolver,
  trimLanguageCode,
} from '../../prismic/utils/link-resolver'

import styles from './header.module.scss'
import LogoSvg from '../../images/logo.inline.svg'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: styles.el__nav__activeLink } : null
}

const NavLink = (props) => <Link getProps={isPartiallyActive} {...props} />

const formatTitleWithDot = (title: string) =>
  `${title.substr(0, title.lastIndexOf(' '))} • ${title.substr(
    title.lastIndexOf(' ')
  )}`

const languageMenuAni = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.6, ease: 'backOut' },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
}

interface Props {
  headerTitle: string,
  pageLang: string,
  navItems: Array<Basiis.NavItem>,
  navHeadingItems: Array<Basiis.NavItem>,
  languageMenuItems,
  transparent: boolean
}

const Header = ({
  headerTitle,
  pageLang,
  navItems,
  navHeadingItems,
  languageMenuItems,
  transparent,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const el = useRef()
  // useClickOutside(el, () => setMenuOpen(false))

  const handleToggleNav = (state: boolean) => {
    setMenuOpen(state)
  }

  let siteTitleLink = ""
  if (pageLang) {
    const siteLang = trimLanguageCode(pageLang)
    siteTitleLink = siteLang === '' ? '/' : `/${siteLang}/`
  }

  return (
    <>
      <header
        className={styles.el}
        data-open={menuOpen}
        data-transparent={transparent}
        ref={el}
      >
        <div className={styles.el__bg} />
        <Container>
          <div className={styles.el__container}>
            <Link
              to={siteTitleLink}
              aria-label={headerTitle}
              className={styles.el__logo__outer}
              onClick={() => (menuOpen ? setMenuOpen(false) : null)}
            >
              <div className={styles.el__logo__inner}>
                <LogoSvg />
              </div>
              {headerTitle && <span className={styles.el__logo__text}>
                {formatTitleWithDot(headerTitle)}
              </span>}
            </Link>
            <motion.nav
              animate={menuOpen ? 'hidden' : 'visible'}
              variants={languageMenuAni}
              className={styles.el__nav}
            >
              <ul className={styles.el__nav__list}>
                {navHeadingItems.map(item => (
                  <li className={styles.el__nav__item} key={item.id}>
                    <NavLink
                      onClick={() => (menuOpen ? setMenuOpen(false) : null)}
                      to={linkResolver(item.url)}
                      className={styles.el__nav__link}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <LanguageSelector
                className={styles.el__nav__language_selector}
                languageMenuItems={languageMenuItems}
              />
            </motion.nav>
            <div className={styles.el__btnToggle}>
              <BtnMenu active={menuOpen} setActive={handleToggleNav} />
            </div>
          </div>
        </Container>
      </header>
      <Menu
        navItems={navItems}
        open={menuOpen}
        onClickNav={() => (menuOpen ? setMenuOpen(false) : null)}
      >
        <LanguageSelector languageMenuItems={languageMenuItems} />
      </Menu>
    </>
  )
}

Header.defaultProps = {
  navItems: [],
  navHeadingItems: [],
  languageMenuItems: [],
  transparent: false,
  pageLang: null,
}

// Header.propTypes = {
//   headerTitle: PropTypes.string.isRequired,
//   navItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//       title: PropTypes.string,
//       url: PropTypes.shape({
//         uid: PropTypes.string,
//         lang: PropTypes.string,
//       }),
//     })
//   ),
//   navHeadingItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//       title: PropTypes.string,
//       url: PropTypes.shape({
//         uid: PropTypes.string,
//         lang: PropTypes.string,
//       }),
//     })
//   ),
//   languageMenuItems: PropTypes.array,
//   transparent: PropTypes.bool,
//   pageLang: PropTypes.string,
// }

export default Header
