import React from 'react'
import PropTypes from 'prop-types'
import { RichText, Elements } from 'prismic-reactjs'
import { Link } from 'gatsby'

import styles from './footer.module.scss'
import Container from '../container'
import Indent from '../indent'
import SocialLinks from '../social-links'
import { linkResolver } from '../../prismic/utils/link-resolver'
import { withHtmlSerializerObfuscator } from '../../utils/html-serializer'

const htmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    // Add a class to hyperlinks
    case Elements.hyperlink:
      if (element.data.link_type === 'Document') {
        return (
          <Link key={element.data.id} to={linkResolver(element.data)}>
            {content}
          </Link>
        )
      }
      return null
    case Elements.paragraph:
      if (
        element.spans.filter(
          span => span.type === 'label' && span.data.label === 'text-block'
        ).length > 0
      ) {
        return (
          <p key={key} className={styles.el__rich_text_container_p_block}>
            {children}
          </p>
        )
      }
      return null
    default:
      // Return null to stick with the default behavior for all other elements
      return null
  }
}

const Footer = props => {
  const { columns, socialLinks, notesSecondary } = props

  const columnsOutput = []
  columns.forEach((col, index) => {
    columnsOutput.push(
      <div
        className={styles.el__column}
        key={`footer-column-${index}`}
        data-column-width={col.column_width}
      >
        <div className={styles.el__column__content}>
          <RichText
            key={`footer-column-${index}-rich-text`}
            render={col.content.raw}
            linkResolver={linkResolver}
            htmlSerializer={withHtmlSerializerObfuscator(htmlSerializer)}
          />
        </div>
      </div>
    )
  })

  return (
    <footer className={styles.el}>
      {!!columnsOutput.length && (
        <Container>
          <Indent>
            <div className={styles.el__rich_text_container}>
              {columnsOutput}
            </div>
          </Indent>
        </Container>
      )}
      <Container>
        <div className={styles.el__secondary}>
          <div className={styles.el__notes}>
            <div className={styles.el__copyright}>
              © {new Date().getFullYear()}
            </div>
            {notesSecondary &&
              <RichText
                linkResolver={linkResolver}
                render={notesSecondary.raw}
                htmlSerializer={htmlSerializer}
              />
            }
          </div>
          <div className={styles.el__socialLinks}>
            {!!socialLinks.length && <SocialLinks items={socialLinks} />}
          </div>
        </div>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  columns: [],
  socialLinks: [],
  notesPrimary: null,
  notesSecondary: null,
}

Footer.propTypes = {
  columns: PropTypes.array,
  socialLinks: PropTypes.array,
  notesPrimary: PropTypes.shape({
    raw: PropTypes.array.isRequired,
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  notesSecondary: PropTypes.shape({
    raw: PropTypes.array.isRequired,
    html: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default Footer
