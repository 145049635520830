import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/core'
import Helmet from 'react-helmet'

import josefinLightWoff from 'typeface-josefin-sans/files/josefin-sans-latin-300.woff'
import josefinLightWoff2 from 'typeface-josefin-sans/files/josefin-sans-latin-300.woff2'

import ralewayWoff from 'typeface-raleway/files/raleway-latin-400.woff'
import ralewayWoff2 from 'typeface-raleway/files/raleway-latin-400.woff2'
import * as base from '../styles/base.scss'
import styles from './layout.module.scss'
import Header from '../components/header'
import Footer from '../components/footer'
import NewsletterSignup from '../components/newsletter-signup'
import PageTransition from '../components/page-transition'
import CookieBanner from '../components/cookie-banner'
import SEO from '../components/metadata/seo'
// import { GridTest } from '../components/grid'

export const TranslationContext = React.createContext({})

const Layout = props => {
  const { site, page, children, location, languageMenuItems } = props
  return (
    <TranslationContext.Provider value={site.translations}>
      <Global
        styles={css`
        /* josefin-sans-300normal - latin */
        @font-face {
          font-family: 'Josefin Sans Light';
          font-style: normal;
          font-weight: 300;
          font-display: block;
          src:
            url('${josefinLightWoff2}') format('woff2'), /* Super Modern Browsers */
            url('${josefinLightWoff}') format('woff'); /* Modern Browsers */
        }
        
        /* raleway-300normal - latin */
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-display: block;
          src:
            url('${ralewayWoff2}') format('woff2'), /* Super Modern Browsers */
            url('${ralewayWoff}') format('woff'); /* Modern Browsers */
        }
        
        ${base}
      `}
      />
      <SEO
        host={site.siteMetadata.siteUrl}
        alternateLanguages={page.alternateLanguages}
        siteTitle={site.title}
        defaultLang={site.siteMetadata.defaultLanguage}
        pathname={location.pathname}
        {...page.seo}
      />
      <div
        className={styles.el}
        data-transparent-header={page.transparentHeader}
      >
        <Header
          headerTitle={site.headerTitle || site.title}
          pageLang={page.lang}
          navItems={site.nav}
          navHeadingItems={site.navHeader}
          path={page.path}
          transparent={page.transparentHeader}
          languageMenuItems={languageMenuItems}
        />

        <Helmet>
          <link
            rel='preload'
            as='font'
            href={josefinLightWoff2}
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={ralewayWoff2}
            crossOrigin='anonymous'
          />
        </Helmet>

        <main className={styles.el__main}>
          <PageTransition location={location}>
            {children}
            {/* <GridTest /> */}
          </PageTransition>
        </main>
        {page.newsletterSubscribe && site.newsletterActionURL && (
          <NewsletterSignup
            heading={site.newsletterHeading}
            description={site.newsletterDescription}
            submitLabel={site.newsletterSubmitLabel}
            actionURL={site.newsletterActionURL}
            fields={site.newsletterFields}
          />
        )}
        {!page.hideFooter && (
          <Footer
            columns={site.footer}
            notesPrimary={site.acknowledgmentStatement}
            notesSecondary={site.footerNotes}
            socialLinks={site.socialLinks}
          />
        )}
        {site.cookiePolicy && <CookieBanner cookiePolicy={site.cookiePolicy} />}
      </div>
    </TranslationContext.Provider>
  )
}

Layout.defaultProps = {
  site: {
    headerTitle: '',
    metaDescription: '',
    acknowledgmentStatement: '',
    nav: [],
    navHeader: [],
    shareImage: '',
    footer: [],
    footerNotes: null,
    newsletterHeading: '',
    newsletterDescription: '',
    newsletterSubmitLabel: '',
    newsletterActionURL: '',
    newsletterFields: [],
    socialLinks: [],
    cookiePolicy: null,
  },
  page: {
    shareImage: '',
    path: '',
    newsletterSubscribe: false,
    transparentHeader: false,
    darkerBg: false,
    hideFooter: false,
  },
  location: {},
  languageMenuItems: []
}

Layout.propTypes = {
  site: PropTypes.shape({
    title: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    metaDescription: PropTypes.string.isRequired,
    acknowledgmentStatement: PropTypes.string,
    nav: PropTypes.arrayOf(PropTypes.object),
    navHeader: PropTypes.arrayOf(PropTypes.object),
    shareImage: PropTypes.string,
    footer: PropTypes.arrayOf(PropTypes.object),
    footerNotes: PropTypes.shape({
      raw: PropTypes.array.isRequired,
      html: PropTypes.string,
      text: PropTypes.string,
    }),
    cookiePolicy: PropTypes.object,
    newsletterHeading: PropTypes.string,
    newsletterDescription: PropTypes.string,
    newsletterSubmitLabel: PropTypes.string,
    newsletterActionURL: PropTypes.string,
    newsletterFields: PropTypes.arrayOf(PropTypes.object),
    socialLinks: PropTypes.arrayOf(PropTypes.object),
    siteMetadata: PropTypes.shape({
      defaultLanguage: PropTypes.string.isRequired,
      siteUrl: PropTypes.string.isRequired,
    }),
    translations: PropTypes.object,
  }),
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    shareImage: PropTypes.string,
    path: PropTypes.string,
    newsletterSubscribe: PropTypes.bool,
    transparentHeader: PropTypes.bool,
    darkerBg: PropTypes.bool,
    hideFooter: PropTypes.bool,
    alternateLanguages: PropTypes.array,
  }),
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  languageMenuItems: PropTypes.array
}

export default Layout
