import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import styles from './cookie-banner.module.scss'
import 'cookieconsent/build/cookieconsent.min.css'
import { TranslationContext } from '../../layout/layout'

const CookieBanner = ({ cookiePolicy }) => {
  const translation = useContext(TranslationContext)
  useEffect(() => {
    if (window && translation) {
      import(/* webpackChunkName: "cookieconsent" */ 'cookieconsent/build/cookieconsent.min.js').then(() => {
        window.cookieconsent.initialise({
          container: document.getElementById('cookie-consent'),
          palette: {
            popup: {
              background: '#edeff5',
              text: '#838391',
            },
            button: {
              background: '#4b81e8',
            },
          },
          location: true,
          law: {
            countryCode: 'ES',
            // regionalLaw: true,
          },
          content: {
            // header: 'Cookies used on the website!',
            message: translation.cookie_notice,
            dismiss: translation.allow_cookies,
            allow: translation.allow_cookies,
            deny: translation.decline,
            link: translation.learn_more,
            href: cookiePolicy.url,
            // close: '&#x274c;',
            // policy: 'Cookie Policy',
            // target: '_blank',
          },
          // type: 'opt-in',
        })
      })
    }
  }, [translation])

  return <div id='cookie-consent' className={styles.el} />
}

CookieBanner.defaultProps = {}

CookieBanner.propTypes = {
  cookiePolicy: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default CookieBanner
