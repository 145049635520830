import React from 'react'
import { RichText as PrismicRichText } from 'prismic-reactjs'

import styles from './rich-text.module.scss'

interface Props {
  render: Array<any>,
  headingStyles: boolean,
  listStyles: boolean,
}

const RichText = (props: Props) => {
  const { render, headingStyles, listStyles } = props
  return (
    <div
      className={styles.el}
      data-headings={headingStyles}
      data-lists={listStyles}
    >
      <PrismicRichText render={render} />
    </div>
  )
}

RichText.defaultProps = {
  headingStyles: true,
  listStyles: true,
}



export default RichText
