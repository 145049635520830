import React, { ReactNode, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import styles from './menu.module.scss'
import Link, { Props as LinkProps } from '../link/link'
import { linkResolver } from '../../prismic/utils/link-resolver'

const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) => {
  return isPartiallyCurrent ? { className: styles.el__nav__link__active } : null
}

const NavLink = (props: LinkProps) => <Link getProps={isPartiallyActive} {...props} />

export interface Props {
  navItems: Array<Basiis.NavItem>,
  open: boolean,
  onClickNav: Function,
  children: ReactNode,
}

const Menu = ({ navItems, open, onClickNav, children }: Props) => {
  const [hovered, setHovered] = useState<number | null>(null)
  const ani = {
    hidden: {
      x: '-100%',
      transition: { duration: 0.6, ease: 'backOut' },
    },
    visible: {
      x: '0%',
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  }
  const listAni = {
    hidden: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
    visible: { transition: { staggerChildren: 0.07, delayChildren: 0.1 } },
  }

  const itemAni = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    deactivated: { opacity: 0.4 },
  }

  const onMouseOver = (idx: number) => {
    setHovered(idx)
  }

  const onMouseOut = () => {
    setHovered(null)
  }

  const getMotionState = (idx: number) => {
    let state = null
    if (hovered === idx) {
      state = 'visible'
    } else if (hovered !== null) {
      state = 'deactivated'
    }
    return state
  }

  return (
    <motion.div
      className={styles.el}
      animate={open ? 'visible' : 'hidden'}
      variants={ani}
    >
      <motion.ul variants={listAni} className={styles.el__nav}>
        {navItems.map((item, idx) => (
          <motion.li
            variants={itemAni}
            className={classNames(styles.el__nav__item, (!item.title) ? styles.el__nav__item__edge : '')}
            key={item.id}
            onHoverStart={() => onMouseOver(idx)}
            onHoverEnd={() => onMouseOut()}
            animate={getMotionState(idx)}
          >
            {item.url.url ? (
              <NavLink
                onClick={() => onClickNav()}
                // onMouseOver={() => onMouseOver(idx)}
                // onMouseOut={() => onMouseOut(idx)}
                to={linkResolver(item.url)}
                target={item.url.target}
                className={classNames(styles.el__nav__link, item.title.length === 0 ? styles.el__nav__link__empty : ' ')}
              >
                {item.title}
              </NavLink>
            ) : (
                <span className={classNames(styles.el__nav__link, styles.el__nav__link__empty)} />
              )}
          </motion.li>
        ))}
        <motion.div
          variants={itemAni}
          className={styles.el__nav__item_secondary}
        >
          {children}
        </motion.div>
      </motion.ul>
    </motion.div>
  )
}

Menu.defaultProps = {
  navItems: [],
  open: false,
  onClickNav: () => undefined,
  children: null,
}

export default Menu
