import React from 'react'
import { Helmet } from 'react-helmet'
import { linkResolver } from '../../prismic/utils/link-resolver'
import { SiteConfig } from '../../prismic/utils/get-site-config'

const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

const getPageFormatter = (siteTitle: string, pageUid: string) => {
  return (pageTitle: string) => {
    let title = siteTitle
    if (pageTitle && pageUid !== 'home') {
      title = `${pageTitle} | ${title}`
    }
    return title
  }
}

const getHrefLang = (host: string, href: string, languageKey: string) => <link
  key={`meta-alternate-${languageKey}`}
  rel='alternate'
  hrefLang={languageKey}
  href={href}
/>

interface SocialConfig {
  title: string,
  description: string,
  image: string,
  imageSquare: string,
}

interface SeoConfig {
  title: string,
  metaTitle: string,
  description: string,
  lang: string,
  pageUid: string,
  canonicalPathname: string,
  social: SocialConfig,
  noIndex: boolean,
}

export const parseMetadataFromPrismic = (pageUid, node: GatsbyTypes.PrismicBasicPageDataType, lang, site: SiteConfig): SeoConfig => {
  const formatter = getPageFormatter(site.title, pageUid)
  const { title } = node
  const metaTitle =
    node.meta_title && node.meta_title.length ? node.meta_title : title
  const description =
    node.meta_description && node.meta_description.length
      ? node.meta_description
      : null
  const canonicalPathname =
    node.canonical_url && node.canonical_url.length
      ? node.canonical_url
      : null

  const social: SocialConfig = {}
  social.description = node.card_description || description // Set the main description as a default
  social.image = node.card_imageSharp
    ? node.card_imageSharp.childImageSharp.fixed.src
    : site.shareImage
  social.imageSquare = node.card_image_squareSharp
    ? node.card_image_squareSharp.childImageSharp.fixed.src
    : site.shareImageSquare
  social.title = formatter(metaTitle || title)
  return {
    title: formatter(title),
    metaTitle: formatter(metaTitle),
    description,
    social,
    canonicalPathname,
    pageUid,
    lang,
    noIndex: node.no_index,
  }
}


interface Props {
  siteTitle: string,
  host: string,
  pathname: string,
  alternateLanguages: Array<Basiis.Lang>,
  // defaultLang: Basiis.Lang,
  article: boolean,
}

const SEO = (props: Props & SeoConfig) => {
  const {
    title,
    pageUid,
    metaTitle,
    siteTitle,
    host,
    lang,
    // defaultLang,
    social,
    pathname,
    alternateLanguages,
    canonicalPathname,
    article,
    noIndex,
  } = props

  const seo = {
    title: metaTitle || title,
    url: `${host}${pathname || '/'}`,
  }

  const currentPageUrl = `${host}${linkResolver({ uid: pageUid, lang, })}`
  let canonicalUrl = currentPageUrl
  if (canonicalPathname) {
    if (validURL(canonicalPathname)) {
      canonicalUrl = canonicalPathname
    }
  }

  return (
    <>
      <Helmet title={seo.title} defaultTitle={siteTitle}>
        <html lang={lang} />
        <meta charSet='utf-8' />
        {social.description && (
          <meta name='description' content={social.description} />
        )}
        <link rel='canonical' href={canonicalUrl} />
        {noIndex && <meta name='robots' content='noindex' />}
        <meta property='og:locale' content={lang} />
        {alternateLanguages &&
          alternateLanguages.map(language => getHrefLang(host, `${host}${linkResolver(language)}`, language.lang))
        }
        {/* show self href */
          getHrefLang(host, currentPageUrl, lang)
        }
        {alternateLanguages.map(language =>
          language.lang !== lang ? (
            <meta key={`meta - locale - ${language.lang}`} property='og:locale:alternate' content={language.lang} />
          ) : null
        )}
        {seo.url && <meta property='og:url' content={seo.url} />}
        {
          (article ? <meta property='og:type' content='article' /> : <meta property='og:type' content='website' />)
        }
        {social.title && <meta property='og:title' content={social.title} />}
        {
          social.description && (
            <meta property='og:description' content={social.description} />
          )
        }
        {social.image && <meta property='og:image' content={social.imageSquare} />}
        <meta name='twitter:card' content='summary_large_image' />
        {social.title && <meta name='twitter:title' content={social.title} />}
        {
          social.description && (
            <meta name='twitter:description' content={social.description} />
          )
        }
        {social.image && <meta name='twitter:image' content={social.image} />}
        <link rel='image_src' href={social.imageSquare} />
        {/* <script type='application/ld+json'>
          {`
            '@context': 'https://schema.org',
            '@type': 'Company',
            'url': '${seo.url}',
            'name': '${seo.title}',
          `}
        </script> */}
      </Helmet >
    </>
  )
}

export default SEO


SEO.defaultProps = {
  social: {},
  image: null,
  siteTitle: null,
  metaTitle: null,
  canonicalPathname: null,
  alternateLanguages: null,
  pathname: null,
  article: false,
}
