import React from 'react'
import Obfuscate from 'react-obfuscate'
import { Elements } from 'prismic-reactjs'

export const htmlSerializerObfuscator = (type: string, element, content: string, children, key) => {
    if (type === Elements.hyperlink && element.data.link_type === 'Web') {
        if (element.data.url.startsWith('tel:')) {
            return <Obfuscate key={key} tel={content} linkText='/contacto/' />
        }
        if (element.data.url.startsWith('mailto:')) {
            return <Obfuscate key={key} email={content} linkText='/contacto/' />
        }
    }
    return null
}

export const withHtmlSerializerObfuscator = (serializer) => {
    return (type: string, element, content: string, children, key) => {
        let result = htmlSerializerObfuscator(type, element, content, children, key)

        if (result === null) {
            result = serializer(type, element, content, children, key)
        }
        return result
    }
}

