import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import styles from './page-transition.module.scss'

const fadeDuration = 0.8;

const containerAni = {
  initial: {},
  enter: {},
  exit: {},
}

const contentAni = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      fadeDuration,
      delay: fadeDuration + 0.15,
    },
  },
  exit: {
    opacity: 0,
    transition: { fadeDuration },
  },
}

const overlayAni = {
  initial: {
    translateY: '100%',
  },
  enter: {
    transition: {
      duration: 1,
      ease: 'easeInOut',
      times: [0, 0.4, 0.6, 1],
    },
    translateY: ['100%', '0%', '0%', '-100%'],
  },
}

const PageTransition = ({ children, location }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        variants={containerAni}
        initial='initial'
        animate='enter'
        exit='exit'
      >
        <div>
          <div className={styles.el__overlay}>
            <motion.div
              key={`${location.pathname}_overlay`}
              className={styles.el__overlay__inner}
              variants={overlayAni}
              initial='initial'
              animate='enter'
              exit='exit'
            />
          </div>
          <motion.div
            key={`${location.pathname}_content`}
            variants={contentAni}
          >
            {children}
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

PageTransition.defaultProps = {
  children: undefined,
  location: {},
}

PageTransition.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default PageTransition
