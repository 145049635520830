import React, { ReactNode } from 'react'
import Layout from '../../layout'
import { getSiteConfig } from '../utils/get-site-config'
import { linkResolver } from '../utils/link-resolver'
import { parseMetadataFromPrismic } from '../../components/metadata/seo'

interface Props {
  children?: ReactNode
  path: string,
  pageContext: GatsbyTypes.SitePageContext,
  location: object,
}

const LayoutPrismic = (props: Props) => {
  const { pageContext, children, location } = props
  const site =
    getSiteConfig(pageContext.lang || pageContext.site?.defaultLanguage || 'es-es')

  // Only show languages enabled in .env as alternates
  const alternates = pageContext.alternate_languages && pageContext.alternate_languages.filter(
    lang => site.siteMetadata.languages?.indexOf(lang?.lang) !== -1
  ) || []

  const pageData = {
    title: pageContext.title,
    path: location.pathname,
    lang: pageContext.lang,
    transparentHeader: pageContext.transparentHeader,
    newsletterSubscribe: pageContext.newsletterSubscribe,
    shareImage: pageContext.shareImage,
    alternateLanguages: alternates,
    seo: pageContext.data
      ? parseMetadataFromPrismic(
        pageContext.uid,
        pageContext.data,
        pageContext.lang,
        site
      )
      : {},
  }

  // Find all the alternate languages and see if they're enabled

  const languageMenuItems = alternates
    .map(page => ({
      code: page.lang,
      type: 'Document',
      url: linkResolver(page),
      active: false,
    }))
    .filter(({ code }) => site.siteMetadata.languages.indexOf(code) !== -1)

  if (languageMenuItems.length) {
    // Add the current language to the top
    languageMenuItems.unshift({
      code: pageContext.lang,
      type: 'Document',
      url: location.pathname,
      active: true,
    })
    languageMenuItems.sort((a, b) => (a.code > b.code ? 1 : -1))
  }


  return (
    <Layout
      site={site}
      page={pageData}
      location={location}
      languageMenuItems={languageMenuItems}
    >
      {children}
    </Layout>
  )
}

LayoutPrismic.defaultProps = {
  children: undefined,
  location: {},
}


export default LayoutPrismic
